import axios from 'axios';
import React, { Suspense, useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as CONSTANTS from '../../CONSTANTS'
import { Button, message, Pagination, Skeleton, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import 'antd/dist/antd.min.css'
import { LoadingOutlined } from '@ant-design/icons/LoadingOutlined';

let timeout;
let currentValue;

export default function Question({ token }) {
  const tableRef = useRef(null);
  const [key, setKey] = useState(1);
  var params = useParams();
  const { t } = useTranslation('home');
  const [load, setLoading] = useState(true);

  const [page, setCurrent] = useState(1);
  const [searchWord, setSearchWord] = useState(null);
  const [tag, setTag] = useState('');
  const [description, setDescription] = useState(null);
  const [replies, setReplies] = useState(null);


  const [appointments, setAppointmentsData] = useState(null);
  const [uploadId, setUploadId] = useState(false);

  const [imageUrl, setImageUrl] = useState();
  const [answer, setAnswer] = useState('');
  const [name, setName] = useState('');

  const [user, setUser] = useState(null);

  const [serialNumber, setSerialNumber] = useState('');


  const answerQuestion = () => {
    if (answer == '') {
      message.error("Answer is required")
      return false;
    }



    let data = JSON.stringify({

      "doctorAnswer": answer,
      'answered': true




    });
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token,
      "content-type": "multipart/form-data"
    };
    axios.put(CONSTANTS.DEFAULT_URL + 'medical-questions/' + params.id, { data: data }
      , {
        headers: headers
      })
      .then(function (response) {
        if (response.status === 200) {
          message.success("updated Successfully");
          navigate('/');

        } else {
          message.error("Can't update");

        }

      })
      .catch(function (error) {
        message.error(error.response.data.error.message);
        navigate('/');


        if (error.response.status === 403) {
          navigate('/login');


        }
        console.log(error);
      })
  }
  const createLead = () => {
    if (description == '') {
      message.error("Description is required")
      return false;
    }




    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${CONSTANTS.DEFAULT_URL}leads`,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`

      },
      data: JSON.stringify({
        "data": {
          "first_name": user.data.attributes.profile.data.attributes.firstname,
          "last_name": user.data.attributes.profile.data.attributes.lastname,
          "freetext": description,
          "compaign": "medical_question",
          "direction": "in",
          "tag": tag,
          "user": user.data.attributes.profile.data.attributes.user?.data.id,

          "user_type": "patient",
          "type": "patient",
          'mobileNumber': user.data.attributes.profile.data.attributes.user?.data.attributes.mobileNumber,
        }
      })
    };

    axios.request(config)
      .then((response) => {
        if (response.status === 200) {
          message.success("created Successfully");


        } else {
          message.error("Can't create");

        }

      })
      .catch(function (error) {
        message.error(error.response.data.error.message);


        if (error.response.status === 403) {
           navigate('/login');


        }
        console.log(error);
      })
  }

  const updatePage = title => {
    setLoading(true);


    setLoading(false);
  }
  const navigate = useNavigate();
  useEffect(() => {
    setLoading(true);
    if (token != null) {
      setLoading(true);
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
      axios.get(CONSTANTS.DEFAULT_URL + 'users/me', {
        headers: headers
      })
        .then(function (response) {
          // handle success
          //   http://3.70.182.78:1337/api/orders?filters[patient][id]=1007
          var query = '';
          setLoading(true);


          axios.get(CONSTANTS.DEFAULT_URL + 'medical-questions/' + params.id + '?filters[$and][0][answered][$null]=true&populate[speciality]=*&populate[attachments]=*&populate[patient][populate][profile][populate]=user', {
            headers: headers
          }).then(function (appointmentData) {
            setAppointmentsData(appointmentData.data.data);
            setAnswer(appointmentData.data.data.attributes.doctorAnswer);
            setUser(appointmentData.data.data.attributes.patient);

            axios.get(CONSTANTS.DEFAULT_URL + 'medical-question-replies/?filters[$and][0][medical_question][id][$eq]=' + params.id, {
              headers: headers
            }).then(function (da) {
              console.log("da",da.data.data);
              setReplies(da.data.data);


              setLoading(false);
            })

            setLoading(false);
          })




        })
        .catch(function (error) {
          navigate('/login');

          setLoading(true);

          if (error.response.status === 403) {
            navigate('/login');


          }
          console.log(error);
        })

    } else {
      navigate('/login');

      setLoading(true);

    }

  }, [page]);

  const [data, setData] = useState([]);

  return (

    <div className='container-fluid'>

      <Suspense>
        {load === false ? (<><div class="breadcrumb-bar">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-md-12 col-12">
                <nav aria-label="breadcrumb" class="page-breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/">Home </a></li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
          <div class="content">
            <div class="container-fluid">
              <div class="row">


                <div class="col-md-12">

                  <div class="tab-pane fade show active">
                    <div class="card card-table mb-0">


                      <div class="card-body">

                        <>  {appointments.attributes.show_info !== false ? (<> <div class="form-group">
                          <label>Patient</label>
                          <p>{appointments.attributes?.patient?.data?.attributes?.profile?.data?.attributes?.firstname + ' ' + appointments?.attributes?.patient?.data?.attributes?.profile?.data?.attributes?.lastname}</p>

                        </div>



                          <div class="form-group">
                            <label>Age</label>
                            <p>{appointments.attributes.age}</p>

                          </div></>) : (<h2 className='text-danger'>Request to hide information</h2>)}</>



                        <div class="form-group">
                          <label>Speciality</label>
                          <p>{appointments.attributes.speciality.data?.attributes.name}</p>

                        </div>
                        <div class="form-group">
                          <label>Explain</label>
                          <p>{appointments.attributes.explain}</p>

                        </div>

                        <div class="form-group">
                          <label>Answer</label>
                          <textarea className='form-control' style={{ "height": "200px" }} onChange={(e) => { setAnswer(e.target.value) }}>{appointments.attributes.doctorAnswer}</textarea>

                        </div>
                        Replyies
                        <table>
                          <tbody>
                        {replies && replies?.map((a, i) => {

return (
  <><tr>


    <td>{a.attributes.reply}</td>

  </tr>
  </>)
}



)}
</tbody>
</table>
                        <h2>Attachments</h2>
                        {appointments.attributes?.attachments.data && appointments.attributes?.attachments.data.length > 0 ? (<table>
                          <th>Attachment</th>
                          <tbody>


                            {appointments.attributes?.attachments.data.map((a, i) => {

                              return (
                                <><tr>


                                  <td><a target="_blank" href={a.attributes?.url} rel="noreferrer">Download</a></td>

                                </tr>
                                </>)
                            }



                            )}






                          </tbody>
                        </table>) : (<></>)}
                       
                        <div class="form-group">
                          <button className='btn btn-success' onClick={() => { answerQuestion() }}>Save</button>

                        </div>


                        <div class="form-group">
                          <label>tag</label>
                          <input className='form-control' onChange={(e) => { setTag(e.target.value) }} />

                        </div>

                        <div class="form-group">
                          <label>Description</label>
                          <textarea className='form-control' style={{ "height": "200px" }} onChange={(e) => { setDescription(e.target.value) }}></textarea>

                        </div>

                        <div class="form-group">
                          <button className='btn btn-success' onClick={() => { createLead() }}>Create Lead</button>

                        </div>


                      </div>
                    </div>
                  </div>


                </div>

              </div>
            </div>

          </div></>) : (<Skeleton active />)}

      </Suspense>
    </div>

  )
}
