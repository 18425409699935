import axios from 'axios';
import React, { Suspense, useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import * as CONSTANTS from '../../CONSTANTS'
import { Button, message  ,Pagination,Select,Skeleton} from 'antd';
import { useTranslation } from 'react-i18next';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import 'antd/dist/antd.min.css'
import { Col, Row } from 'react-bootstrap';
import { Option } from 'antd/lib/mentions';

export const Home = ({token}) => {
	const tableRef = useRef(null);
	const [key , setKey] = useState(1);
	const [limit , setLimit] = useState(100);

	const {t} = useTranslation('home');
    const [load , setLoading] = useState(true);
    const [appointmentsLoad , setAppointmentsLoading] = useState(false);
    const [total , setTotal] = useState(0);
    const [page , setCurrent] = useState(0);
    const [searchWord , setSearchWord] = useState(null);
    const [answered , setAnswered] = useState(null);
	const [isLoaded, setDataLoaded] = useState(false);
	const [specialitiesData, setSpecialities] = useState(false);
	const [plans , setPlans] = useState([]);
	const [specialitie, setSpecialitie] = useState(undefined);
	const [dateFrom, setDateFrom] = useState(null);
	const [dateTo, setDateTo] = useState(null);

	
	const itemRender = (_, type, originalElement) => {
		if (type === 'prev') {
		  return <a>Previous</a>;
		}
		if (type === 'next') {
		  return <a>Next</a>;
		}
		return originalElement;
	  };
    const [appointments , setAppointmentsData] = useState([]);
	const updatePage = title => {
		setLoading(true);


		setAppointmentsLoading(true);
		setLoading(false);
	 }
    const navigate = useNavigate();
    useEffect(() => {
		(async () => {
			setDataLoaded(false);
			await   getSpecialties().then((response)=>{
				console.log(response.data.data);
			  setSpecialities(response.data.data);
		})
		.catch((error) =>{         setSpecialities([]);
		});
		
			setDataLoaded(true);
	  
		  })();
setLoading(true);
        if(token != null ){
            setLoading(true);
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token
              }
            axios.get(CONSTANTS.DEFAULT_URL+'users/me' , {
                headers: headers
              })
    .then(function (response) {
      // handle success
    //   http://3.70.182.78:1337/api/orders?filters[patient][id]=1007
	var query = '';
	setLoading(true);
// 	if(searchWord){
// query += '&filters[name][$startsWith]='+searchWord;
// 	}
var query = '';
if(answered !== null){
   query += '&filters[$and][0][answered][$eq]='+answered;

}else{
	query = '';
}if(specialitie){
	query += '&filters[$and][0][speciality][id][$eq]='+specialitie;
 
 }
if(dateFrom !== null){
  query += "&filters[$and][0][createdAt][$gte]="+dateFrom
}

if(dateTo !== null){
	query += "&filters[$and][1][createdAt][$lte]="+dateTo
  }

	// supplier-products?populate[image][fields]=name,url&populate[product_category][fields]=name&filters[supplier][type]=pharmacy&pagination[pageSize]=10&filters[name][$startsWith]=p
	axios.get(CONSTANTS.DEFAULT_URL+'medical-questions?pagination[page]='+page+'&pagination[pageSize]='+limit+'&populate[speciality]=*&populate[patient][populate][0]=profile&&sort=id:DESC&populate[patient][populate][profile][populate][user]=*'+query , {
        headers: headers
      }).then(function (appointmentData){
setTotal(appointmentData.data.meta.pagination.total-1);
            setAppointmentsData(appointmentData.data.data);

            setAppointmentsLoading(true);
		setLoading(false);
      })


        

    })
    .catch(function (error) {
        navigate('/login');

    setLoading(true);

    if(error.response.status === 403){
        navigate('/login');


    }
      console.log(error);
    })

}else{
    navigate('/login');

    setLoading(true);

}

}, [page,answered,specialitie,dateFrom,dateTo]);

const onChange = (page) => {
    console.log(page);
    setCurrent(page);
  };
  const setSearchName = (word) => {
    console.log(word);
	setSearchWord(word);

  };
//   const searchFunction = ()=>{
// 	setLoading(true);
// 	setCurrent(1);

// 	const headers = {
// 		'Content-Type': 'application/json',
// 		'Authorization': 'Bearer '+token
// 	  }
// 	var query = '';
// 	if(searchWord){
// 		query += '&filters[name][$startsWith]='+searchWord;
// 			}
		
// 			axios.get(CONSTANTS.DEFAULT_URL+'suppliers?pagination[page]='+page+'&pagination[pageSize]=30&filters[type]=pharmacy'+query , {
// 				headers: headers
// 			  }).then(function (appointmentData){
// 		console.log(appointmentData.data.data);
// 		setTotal(appointmentData.data.meta.pagination.total);
// 					setAppointmentsData(appointmentData.data.data);
		
// 					setAppointmentsLoading(true);
// 				setLoading(false);
// 			  })
//   }
const answerSelect = (e)=>{
	console.log(e);
      setAnswered(e);
}

const dateFromSelect = (e)=>{
      setDateFrom(e);
}

const dateToSelect = (e)=>{
	setDateTo(e);
}
const onspecialtiy = (e)=>{
	console.log(e);
      setSpecialitie(e);
}
const getSpecialties =async()=>{
    var requestOptions = {
        method: 'GET',
        redirect: 'follow' 
      };
      
   return    axios.get(CONSTANTS.DEFAULT_URL+"specialities?locale=en&locale=ar&pagination[limit]=1000&pagination[start]=0", requestOptions)
        
}
  return (

         <div className='container-fluid'>

  <Suspense>
{load === false ? (<><div class="breadcrumb-bar">
				<div class="container-fluid">
					<div class="row align-items-center">
						<div class="col-md-12 col-12">
							<nav aria-label="breadcrumb" class="page-breadcrumb">
								<ol class="breadcrumb">
									<li class="breadcrumb-item"><a href="/">Home</a></li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</div>
            <div class="content">
				<div class="container-fluid">
					<div class="row">
					
<Col md={2}>

<Select onChange={answerSelect}  placeholder="Answer Type">
					<Select.Option value="null">Empty</Select.Option>

    <Select.Option value="true">Answerd</Select.Option>
	<Select.Option value="false">No Yet</Select.Option>

  </Select>         		
							
                  </Col>

				  <Col md={2}>
     <input type='date' value={dateFrom} onChange={(e)=>dateFromSelect(e.target.value)} 	/>						
                  </Col>

				  <Col md={2}>
     <input type='date' value={dateTo}  onChange={(e)=>dateToSelect(e.target.value)}	/>						
                  </Col>
				  <Col md={2}>
					<Select showSearch style={{"width":"600px"}}
					optionFilterProp="children"
					filterOption={(input, option) => (option?.label ?? '').includes(input)}
					filterSort={(optionA, optionB) =>
					  (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
					}
					  onChange={onspecialtiy}  placeholder="Specialtiy">
						
					<>			{specialitiesData.length > 0 ? (

	specialitiesData.map((dd, i) => {
				return (	<><Select.Option value={dd.id} label={dd?.attributes?.name}>{dd?.attributes?.name}</Select.Option></>)
			})):(<></>)}
			 </>

  </Select></Col>
						
						<div class="col-md-12">
		

			


				<br/>
				   <br/>
				   {/* <Row>
				 <Col md={6}>
				 <input type={'text'} placeholder='Search' value={searchWord} className='form-control' onChange={(e)=>{setSearchName(e.target.value)}} />
				 </Col>
				 <Col md={3}>
				 <button className='btn btn-success' onClick={()=>{searchFunction(1)}}>Search</button>				 </Col>
				  

				   </Row> */}
					<div  class="tab-pane fade show active">
								<div class="card card-table mb-0">
									<div class="card-body">
										<div class="table-responsive">
											<table class="table table-hover table-center mb-0" ref={tableRef}> 
												<thead>
													<tr>
													<th>{t('Patient')}</th>
													<th>{t('Patient Phone')}</th>
													<th>{t('answered')}</th>
													<th>{t('answered at')}</th>

													<th>{t('Specialty')}</th>
													<th>{t('Date')}</th>

														{/* <th>{t('Name')}</th> */}
														
														<th>{t('Edit')}</th>

														
														<th></th>
													</tr>
												</thead>
												<tbody>
											
				{appointmentsLoad ? (
appointments.length > 0 ? (
<>
	{appointments.map((profile, i) => {
	 
	  return (
	 <><tr>
		{profile.attributes.show_info !== false  ? (<>  <td>{profile.attributes?.patient?.data?.attributes?.profile?.data?.attributes?.firstname+' '+profile?.attributes?.patient?.data?.attributes?.profile?.data?.attributes.lastname??''}</td>
			  <td>{profile.attributes?.patient?.data?.attributes?.profile?.data?.attributes?.user?.data?.attributes?.mobileNumber}</td></>) : (<><td className='text-danger'>Requst to hide</td><td className='text-danger'>Requst to hide</td></>)}
			
			  <td>  {profile.attributes.answered === true ? (<><span className='text-success'>Answered</span></>):(<><span className='text-danger'>Not Yet</span></>)}	 </td>
			  <td>{profile.attributes.updatedAt}</td>


			  <td>{profile.attributes.speciality.data?.attributes.name??''}</td>
	  <td>{profile.attributes.createdAt}</td>


	  <td><Link to={'/question/'+profile.id}>Edit</Link></td>

	  </tr>
	 </>)
	}
	)}
	<Pagination style={{"width":"800px"}} itemRender={itemRender}   showTotal={(total) => `Total ${total} items`} defaultPageSize={limit}  defaultCurrent={1} current={page} total={total} onChange={onChange} /></>) : (
	  <>No Data</>
	)
  ) : (
	<>
	<Skeleton  avatar
paragraph={{
rows: 4,
}} active />
   </>
  )}
					
									
					</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						
				
							</div>	
							
					</div>
				</div>

			</div></>) : (<Skeleton active />)}

	  </Suspense>
		</div>

  )
}
